<template>
  <tr>
    <td :class="firstCellClasses">{{ group.label }}</td>
    <td class="text-right">
      {{ minutesToHoursMinutes(group.minutes) }}
    </td>
    <td class="text-right" v-if="parentLevel">
      {{ (group.minutes / parentLevel.minutes * 100).toFixed(1) }} %
    </td>
    <td v-else></td>
    <td class="fit" v-if="parentLevel && parentLevel.hasFallbackGroup">
      <em v-if="!group.isFallbackGroup">
        {{ (group.minutes / (parentLevel.minutes - parentLevel.fallbackMinutes) * 100).toFixed(1) }} %
      </em>
      <em v-else>
        {{ minutesToHoursMinutes(parentLevel.minutes - parentLevel.fallbackMinutes) }}
      </em>
    </td>
    <td v-else></td>
    <td class="text-right">
      {{ (group.minutes / rootLevel.minutes * 100).toFixed(1) }} %
    </td>
    <td class="fit" v-if="rootLevel.hasFallbackGroup && !isRootFallbackChildren">
      <em>{{ (group.minutes / (rootLevel.minutes - rootLevel.fallbackMinutes) * 100).toFixed(1) }} %</em>
    </td>
    <td v-else></td>
  </tr>
</template>

<script>
  import { minutesToHoursMinutes } from '../utils'

  export default {
    name: 'StatisticRow',
    props: {
      group: Object,
      parentLevel: Object,
      rootLevel: Object,
      isRootFallbackChildren: Boolean,
      firstCellClasses: Array,
    },

    methods: {
      minutesToHoursMinutes,
    }
  }
</script>

<style scoped>

</style>
