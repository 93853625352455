<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      class="px-5 py-3"
    >
      <template
        v-slot:heading
      >
        <div
          class="d-flex flex-wrap align-top"
          style="margin: -15px 0"
        >
          <div class="text-h4 font-weight-light mt-7">
            Analyse activité
          </div>
          <v-spacer/>
          <v-col>
            <v-row>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="humanizedDates"
                    label="Période"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  scrollable
                  first-day-of-week="1"
                  show-week
                  show-current
                  locale="fr-fr"
                  color="green lighten-1"
                  :max="todayISO"
                  range
                  @change="$refs.menu.save(dates)"
                />
              </v-menu>
            </v-row>
            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="level1Selector"
                  :items="level1Selectors"
                  item-text="label"
                  item-value="id"
                  label="Niveau 1"
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="level2Selector"
                  :items="level2Selectors"
                  item-text="label"
                  item-value="id"
                  label="Niveau 2"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="level3Selector"
                  :items="level3Selectors"
                  item-text="label"
                  item-value="id"
                  label="Niveau 3"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="level4Selector"
                  :items="level4Selectors"
                  item-text="label"
                  item-value="id"
                  label="Niveau 4"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </template>
      <v-simple-table class="table">
        <thead>
        <tr>
          <th class="primary--text" @click="level1Order = false">
            {{ level1SelectorObject.label }}
            <v-icon v-if="!level1Order">mdi-chevron-down</v-icon>
          </th>
          <th class="primary--text fit text-right" @click="level1Order = true">
            Temps
            <v-icon v-if="level1Order">mdi-chevron-up</v-icon>
          </th>
          <th class="fit"></th>
          <th class="fit"></th>
          <th class="primary--text fit text-right" @click="level1Order = true">
            Pro Rata
            <v-icon v-if="level1Order">mdi-chevron-up</v-icon>
          </th>
          <th class="font-weight-light primary--text fit" v-if="level1.hasFallbackGroup" @click="level1Order = true">
            (hors non-catégorisé)
          </th>
          <th v-else></th>
        </tr>
        </thead>

        <tbody>
        <template
          v-for="group in sortedLevel1"
        >
          <statistic-row
            @click.native="selectSubgroup(group.label)"
            :key="group.name"
            :group="group"
            :root-level="level1"
            :is-root-fallback-children="group.isFallbackGroup"
          ></statistic-row>
          <template v-if="level2Selector && selectedLevel1 === group.label">
            <tr class="grey lighten-4">
              <th class="pl-8 font-weight-light primary--text" @click="level2Order = false">
                {{ level2SelectorObject.label }}
                <v-icon v-if="!level2Order">mdi-chevron-down</v-icon>
              </th>
              <th class="primary--text font-weight-light fit text-right" @click="level2Order = true">
                Temps
                <v-icon v-if="level2Order">mdi-chevron-up</v-icon>
              </th>
              <th class="font-weight-light primary--text text-right" @click="level2Order = true">
                Pro Rata groupe
                <v-icon v-if="level2Order">mdi-chevron-up</v-icon>
              </th>
              <th class="font-weight-light primary--text" v-if="level2.hasFallbackGroup" @click="level2Order = true">
                <em>hors non-catégorisé</em>
              </th>
              <th v-else></th>
              <th class="font-weight-light primary--text text-right"></th>
              <th></th>
            </tr>
            <template v-for="subgroup in sortedLevel2">
              <statistic-row
                class="grey lighten-4"
                @click.native="selectSubsubgroup(subgroup.label)"
                :key="subgroup.name"
                :group="subgroup"
                :parent-level="level2"
                :root-level="level1"
                :is-root-fallback-children="group.isFallbackGroup"
                :first-cell-classes="['pl-8']"
              ></statistic-row>
              <template v-if="level3Selector && selectedLevel2 === subgroup.label">
                <tr class="grey lighten-2">
                  <th class="pl-12 font-weight-light primary--text" @click="level3Order = false">
                    {{ level3SelectorObject.label }}
                    <v-icon v-if="!level3Order">mdi-chevron-down</v-icon>
                  </th>
                  <th class="primary--text font-weight-light fit text-right" @click="level3Order = true">
                    Temps
                    <v-icon v-if="level3Order">mdi-chevron-up</v-icon>
                  </th>
                  <th class="font-weight-light primary--text text-right" @click="level3Order = true">
                    Pro Rata sous-groupe
                    <v-icon v-if="level3Order">mdi-chevron-up</v-icon>
                  </th>
                  <th class="font-weight-light primary--text" v-if="level3.hasFallbackGroup" @click="level3Order = true">
                    <em>hors non-catégorisé</em>
                  </th>
                  <th v-else></th>
                  <th class="font-weight-light primary--text text-right"></th>
                  <th class="font-weight-light primary--text text-right"></th>
                </tr>
                <template v-for="subsubgroup in sortedLevel3">
                  <statistic-row
                    @click.native="selectSubsubsubgroup(subsubgroup.label)"
                    class="grey lighten-2"
                    :key="subsubgroup.name"
                    :group="subsubgroup"
                    :parent-level="level3"
                    :root-level="level1"
                    :is-root-fallback-children="group.isFallbackGroup"
                    :first-cell-classes="['pl-12']"
                  ></statistic-row>
                  <template v-if="level4Selector && selectedLevel3 === subsubgroup.label">
                    <tr class>
                      <th class="pl-16 font-weight-light primary--text" @click="level4Order = false">
                        {{ level4SelectorObject.label }}
                        <v-icon v-if="!level4Order">mdi-chevron-down</v-icon>
                      </th>
                      <th class="primary--text font-weight-light fit text-right" @click="level4Order = true">
                        Temps
                        <v-icon v-if="level4Order">mdi-chevron-up</v-icon>
                      </th>
                      <th class="font-weight-light primary--text text-right" @click="level4Order = true">
                        Pro Rata sous-groupe
                        <v-icon v-if="level4Order">mdi-chevron-up</v-icon>
                      </th>
                      <th class="font-weight-light primary--text" v-if="level4.hasFallbackGroup" @click="level4Order = true">
                        <em>hors non-catégorisé</em>
                      </th>
                      <th v-else></th>
                      <th class="font-weight-light primary--text text-right"></th>
                      <th class="font-weight-light primary--text text-right"></th>
                    </tr>
                    <template v-for="subsubsubgroup in sortedLevel4">
                      <statistic-row
                        :key="subsubsubgroup.name"
                        :group="subsubsubgroup"
                        :parent-level="level4"
                        :root-level="level1"
                        :is-root-fallback-children="group.isFallbackGroup"
                        :first-cell-classes="['pl-16']"
                      ></statistic-row>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>

        <tr>
          <td
            class="font-weight-light text-body-2 pt-4"
          >
            Total
          </td>
          <td
            class="text-right font-weight-light text-body-2 pt-4"
          >
            {{ minutesToHoursMinutes(level1.minutes) }}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td v-if="level1.hasFallbackGroup" class="font-weight-light text-body-2 fit">
            <em>{{ minutesToHoursMinutes(level1.minutes - level1.fallbackMinutes) }}</em>
          </td>
          <td></td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>

    <base-material-card
      class="px-5 py-3"
    >
      <template
        v-slot:heading
      >
        <div
          class="d-flex flex-wrap align-top"
          style="margin: -15px 0"
        >
          <div class="text-h4 font-weight-light mt-7">
            Distribution Référence/Délégation
          </div>
        </div>
      </template>
      <v-simple-table class="table">
        <thead>
        <tr>
          <th class="primary--text">
          </th>
        </tr>
        </thead>
        <tbody>
        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import gql from 'graphql-tag'
  import { subMonths, startOfMonth, endOfMonth, subWeeks } from 'date-fns'
  import Vue from 'vue'
  import { minutesToHoursMinutes, toDateISO } from '../../../utils'
  import StatisticRow from '../../../assets/StatisticRow'

  function compareGroupByMinutes (a, b) {
    if (a.isFallbackGroup) {
      return 1
    } else if (b.isFallbackGroup) {
      return -1
    }
    return -((a.minutes > b.minutes) - (a.minutes < b.minutes))
  }

  function compareGroupByLabel (a, b) {
    if (a.isFallbackGroup) {
      return 1
    } else if (b.isFallbackGroup) {
      return -1
    }
    return a.label.localeCompare(b.label)
  }

  function sortGroups (groups, order) {
    groups = { ...groups }
    groups = Object.values(groups)
    if (order) {
      groups = groups.sort(compareGroupByMinutes)
    } else {
      groups = groups.sort(compareGroupByLabel)
    }
    return groups
  }

  function groupItems (activities, selector, ungroupedLabel) {
    const groups = {}
    let sum = 0
    let hasFallbackGroup = false

    for (const activity of activities) {
      const label = schema[selector].getGroupLabel(activity)
      let group = groups[label]
      if (group === undefined) {
        group = {
          label,
          minutes: 0,
          items: [],
        }
        if (label === ungroupedLabel) {
          group.isFallbackGroup = true
          hasFallbackGroup = true
        }
        groups[label] = group
      }
      group.items.push(activity)
      group.minutes += activity.minutes
      sum += activity.minutes
    }

    return {
      groups,
      minutes: sum,
      hasFallbackGroup,
      fallbackMinutes: groups[ungroupedLabel]?.minutes ?? 0,
    }
  }

  const schema = {
    'task': {
      id: 'task',
      label: 'Tâche',
      getGroupLabel (activity) {
        return activity.task.name
      },
    },
    'user': {
      id: 'user',
      label: 'Collaborateur',
      getGroupLabel (activity) {
        return activity.user.last_name.toUpperCase() + ' ' + activity.user.first_name
      },
    },
    'service': {
      id: 'service',
      label: 'Mesure',
      ungroupedLabel: '- Sans mesure -',
      getGroupLabel (activity) {
        if (activity.service) {
          return activity.service.name
        } else {
          return this.ungroupedLabel
        }
      },
    },
    'customer': {
      id: 'customer',
      label: 'Bénéficiaire',
      ungroupedLabel: '- Sans bénéficiaire -',
      getGroupLabel (activity) {
        if (activity.customer) {
          return activity.customer.last_name.toUpperCase() + ' ' + activity.customer.first_name
        } else {
          return this.ungroupedLabel
        }
      },
    },
    'client': {
      id: 'client',
      label: 'Mandant',
      ungroupedLabel: '- Sans mandant -',
      getGroupLabel (activity) {
        if (activity.client) {
          return activity.client.name
        } else {
          return this.ungroupedLabel
        }
      },
    },
    'referrer': {
      id: 'referrer',
      label: 'Référence/Délégation',
      ungroupedLabel: '- Sans bénéficiaire -',
      getGroupLabel (activity) {
        if (activity.customer) {
          if (activity.user_is_referrer) {
            return 'Référence'
          } else {
            return 'Délégation'
          }
        } else {
          return this.ungroupedLabel
        }
      },
    },
    'chargeable': {
      id: 'chargeable',
      label: 'Facturation',
      ungroupedLabel: '- Non Facturable -',
      getGroupLabel (activity) {
        if (activity.task.chargeable) {
          return 'Facturable'
        } else {
          return this.ungroupedLabel
        }
      }
    },
    'date': {
      id: 'date',
      label: 'Date',
      getGroupLabel (activity) {
        return activity.date
      },
    },
  }

  export default {
    name: 'Dashboard',

    data () {
      const today = new Date()
      const start = startOfMonth(subMonths(today, 1), { weekStartsOn: 1 })
      const end = endOfMonth(start, { weekStartsOn: 1 })
      return {
        expanded: false,
        dates: [toDateISO(start), toDateISO(end)],
        today: new Date(),
        activities: [],
        orderTasksByNameTableByTime: false,
        menu: false,
        showDetails: null,
        level1Selector: 'user',
        level1Order: false,
        selectedLevel1: false,
        level2Selector: 'customer',
        level2Order: false,
        selectedLevel2: false,
        level3Selector: 'task',
        level3Order: false,
        selectedLevel3: false,
        level4Selector: null,
        level4Order: false,
      }
    },

    components: {
      StatisticRow,
    },

    computed: {
      headers () {
        return this.dates.map(date => {
          return {
            text: new Date(date).toLocaleDateString('fr-CH', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
            }),
            value: date,
          }
        })
      },
      start () {
        return this.dates[0]
      },
      end () {
        return this.dates[this.dates.length - 1]
      },
      todayISO () {
        return toDateISO(this.today)
      },
      humanizedDates () {
        if (this.dates[0] !== this.dates[1]) {
          return (new Date(this.dates[0])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          }) + ' au ' + (new Date(this.dates[1])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        } else {
          return (new Date(this.dates[0])).toLocaleDateString('fr-CH', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        }
      },
      level1Selectors () {
        const items = Object.values(schema)
        items.sort((a,b) => a.label.localeCompare(b.label))
        return items
      },
      level1 () {
        return groupItems(this.activities, this.level1Selector, this.level1SelectorObject.ungroupedLabel)
      },
      sortedLevel1 () {
        return sortGroups(this.level1.groups, this.level1Order)
      },
      level1SelectorObject () {
        return schema[this.level1Selector]
      },
      level2Selectors () {
        return this.level1Selectors.filter(selector => selector.id !== this.level1Selector)
      },
      level2SelectorObject () {
        return schema[this.level2Selector]
      },
      level2 () {
        return groupItems(this.level1.groups[this.selectedLevel1].items, this.level2Selector, this.level2SelectorObject.ungroupedLabel)
      },
      sortedLevel2 () {
        return sortGroups(this.level2.groups, this.level2Order)
      },
      level3Selectors () {
        return this.level2Selectors.filter(selector => selector.id !== this.level2Selector)
      },
      level3SelectorObject () {
        return schema[this.level3Selector]
      },
      level3 () {
        return groupItems(this.level2.groups[this.selectedLevel2].items, this.level3Selector, this.level3SelectorObject.ungroupedLabel)
      },
      sortedLevel3 () {
        return sortGroups(this.level3.groups, this.level3Order)
      },
      level4Selectors () {
        return this.level3Selectors.filter(selector => selector.id !== this.level3Selector)
      },
      level4SelectorObject () {
        return schema[this.level4Selector]
      },
      level4 () {
        return groupItems(this.level3.groups[this.selectedLevel3].items, this.level4Selector, this.level4SelectorObject.ungroupedLabel)
      },
      sortedLevel4 () {
        return sortGroups(this.level4.groups, this.level4Order)
      },
    },

    watch: {
      '$route.name': {
        handler () {
          this.$nextTick(() => this.$apollo.queries.activities.refetch())
        },
        deep: true,
        immediate: true,
      },
      dates: {
        handler () {
          this.$nextTick(() => this.$apollo.queries.activities.refetch(
            {
              startDate: this.start,
              endDate: this.end,
            },
          ))
        },
        deep: true,
        immediate: true,
      },
    },

    mounted () {
      Vue.prototype.$reloadDashboard = () => {
        this.$apollo.queries.activities.refetch()
      }
    },

    methods: {
      minutesToHoursMinutes,
      toggleShowDetails (name) {
        if (this.showDetails === name) {
          this.showDetails = null
        } else {
          this.showDetails = name
        }
      },
      selectSubgroup (index) {
        if (this.selectedLevel1 === index) {
          this.selectedLevel1 = null
        } else {
          this.selectedLevel1 = index
        }
        this.selectedLevel2 = null
        this.selectedLevel3 = null
      },
      selectSubsubgroup (index) {
        if (this.selectedLevel2 === index) {
          this.selectedLevel2 = null
        } else {
          this.selectedLevel2 = index
        }
        this.selectedLevel3 = null
      },
      selectSubsubsubgroup (index) {
        if (this.selectedLevel3 === index) {
          this.selectedLevel3 = null
        } else {
          this.selectedLevel3 = index
        }
      },
    },

    apollo: {
      activities () {
        return {
          query: gql`
          query ($startDate: String!, $endDate: String!) {
            activities (filter: { _and: [{date: { _gte: $startDate }}, {date: { _lte:  $endDate }}]} , sort: ["task"], limit: -1) {
              id
              date
              minutes
              note
              service {
                id
                name
              }
              task {
                id
                name
                chargeable
              }
              service {
                id
                name
              }
              customer {
                id
                first_name
                last_name
              }
              client {
                id
                name
              }
              user {
                id
                first_name
                last_name
              }
              user_is_referrer
            }
          }
            `,
          variables: {
            startDate: this.start,
            endDate: this.end,
          },
          error (error) {
            const code = error.networkError?.statusCode
            if (code === 400 || code === 401) {
              localStorage.removeItem('jwt')
              this.$router.push({ name: 'Login' })
            }
          },
        }
      },
    },
  }
</script>

<style scoped>
.table table > tbody > tr:hover {
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}
</style>
